<template>
  <div>
    <ds-header title="权益绑定"></ds-header>
    <div>
      <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="name" label="所属项目" prop="projectId">
          <a-select :disabled="isView" @change="projectChange" v-model="form.projectId">
            <a-select-option v-for="item in projectList" :key="item.id">
              {{ item.project_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item ref="name" label="权益名称" prop="benefitId">
          <a-select :disabled="isView" @change="benefitChange" v-model="form.benefitId">
            <a-select-option v-for="item in equities" :key="item.id">
              {{ item.benefitTitle }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="isShowModule" label="所属模块" prop="moduleId">
          <a-select :disabled="isView" v-model="form.moduleId">
            <a-select-option v-for="item in modules" :key="item.id">
              {{ item.moduleName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="适用人群" v-if="isShowCrowds" prop="userLabel">
          <a-select :disabled="isView" mode="multiple" v-model="form.userLabel">
            <a-select-option v-for="item in crowds" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item  label="是否加锁" required prop="lockStatus">
          <a-radio-group :disabled="isView" v-model="form.lockStatus">
            <a-radio :value="0">
              否
            </a-radio>
            <a-radio :value="1">
              是
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item ref="hit" v-if="form.lockStatus === 1" label="提示语" prop="hit">
          <a-input
              v-model="form.hit"
              :disabled="isView"
              @blur="
          () => {
            $refs.hit.onFieldBlur();
          }
        "
          />
        </a-form-model-item>
        <a-form-model-item ref="bindingSort" label="权益排序" prop="bindingSort">
          <a-input-number
              v-model="form.bindingSort"
              :disabled="isView"
              :min="1"
              :max="100"
              @blur="
          () => {
            $refs.bindingSort.onFieldBlur();
          }
        "
          />
        </a-form-model-item>

        <a-form-model-item ref="effectiveDate" label="生效时间"  prop="effectiveDate">
          <a-range-picker
              show-time
              :disabled="isView"
              v-model="form.effectiveDate"
              :disabled-date="disabledDate"
              :disabled-time="disabledDateTime"
          />
        </a-form-model-item>
        <a-form-model-item  label="状态" required>
          <a-radio-group :disabled="isView" v-model="form.bindingStatus">
            <a-radio :value="1">
              启用
            </a-radio>
            <a-radio :value="0">
              禁用
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button v-if="!isView" type="primary" @click="onSubmit">
            保存
          </a-button>
          <a-button style="margin-left: 10px;" @click="resetForm">
            {{ isView ? '返回' : '取消' }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import {
  projectName,
  addEquityBinding,
  editEquityBindingManagement,
  getEquitiesByProjectId,
  getModulesByProjectId,
  findAllCrowd,
  findEquityBindingById
} from '@/api/equityManagement';
import {IMG_API} from '@/config';
import moment from 'moment';
import {mapState} from "vuex";
export default {
  computed: {
    ...mapState({
      projectList: (state) => state.common.projectList
    }),
  },
  data() {
    return {
      // 当前查看或编辑的权益id
      id: '',
      moment,
      // 是否是查看
      isView: false,
      labelCol: {span: 4},
      wrapperCol: {span: 4},
      other: '',
      IMG_API: IMG_API,
      // 是否展示所属模块
      isShowModule: false,
      // 当前项目下的权益
      equities: [],
      // 当前项目下的所有模块
      modules: [],
      // 所有人群
      crowds: [],
      // 是否展示人群
      isShowCrowds: true,
      form: {
        id: '',
        // 所属项目
        projectId: '',
        // 权益
        benefitId: '',
        // 所属模块
        moduleId: '',
        // 适用人群
        userLabel: [],
        // 是否加锁
        lockStatus: 0,
        // 提示语
        hit: '',
        // 权益排序
        bindingSort: '',
        // 生效时间
        effectiveDate:[],
        // 状态
        bindingStatus: 0
      },
      previewVisible: false,
      previewImage: '',
      fileList: [],
      rules: {
        projectId: [{required: true, message: '请选择所属项目', trigger: 'change'}],
        benefitId: [{required: true, message: '请选择权益名称', trigger: 'change'}],
        moduleId: [{required: true, message: '请选择所属模块', trigger: 'change'}],
        userLabel: [{required: true, message: '请选择适用人群', trigger: 'change'}],
        hit: [
            {required: true, message: '请输入提示语', trigger: 'blur'},
            {required: true, max:20, message: '提示语不能超过20字符', trigger: 'blur'},

        ],
        bindingSort: [{required: true, message: '请输入权益排序', trigger: 'blur'}],
        effectiveDate: [{required: true, message: '请选择生效时间', trigger: 'change'}],
      }
    };
  },
  created() {
    if (this.$route.query.type === 'edit') {
      this.id = this.$route.query.id;
      this.findOne()
    }
    if (this.$route.query.type === 'view') {
      this.id = this.$route.query.id;
      this.isView = true
      this.findOne()
    }
    // 查询所有人群
    this.findAllCrowds()
  },
  methods: {
    // 查询所有人群
    findAllCrowds(){
      findAllCrowd().then(res => {
        if (res.code === '200') {
          this.crowds = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 项目change事件
    projectChange() {
      this.form.benefitId = ''
      this.findEquityByProjectId()
      this.findModulesByProductId()
    },
    // 权益change事件
    benefitChange() {
        if(this.form.benefitId) {
          let equity = this.equities.find(item => {
            return item.id === this.form.benefitId
          })
          if (equity.benefitType === 2 || equity.benefitType === 3) {
            this.isShowModule = true
          } else  {
            this.isShowModule = false
          }
        }
    },
    // 根据项目id查询所属权益
    findEquityByProjectId() {
      if (this.form.projectId === '') {
        this.$message.warn('请先选择所属项目')
        return;
      }
      getEquitiesByProjectId(this.form.projectId).then(res => {
        if (res.code === '200') {
          this.equities = res.data
          this.benefitChange()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 查询当前项目下的所属模块
    findModulesByProductId(){
      getModulesByProjectId(this.form.projectId).then(res => {
        if (res.code === '200') {
          this.modules = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 查询一条
    findOne() {
      findEquityBindingById(this.id).then(res => {
        if (res.code === '200') {
          this.form.id = res.data.id
          this.form.projectId = res.data.projectId
          this.projectChange()
          this.findEquityByProjectId()
          this.form.benefitId = res.data.benefitId
          this.form.moduleId = res.data.moduleId
          this.form.userLabel = res.data.userLabel
          this.form.lockStatus = res.data.lockStatus
          this.form.hit = res.data.hit
          this.form.bindingSort = res.data.bindingSort
          this.form.effectiveDate.push(res.data.startTime)
          this.form.effectiveDate.push(res.data.endTime)
          this.form.bindingStatus = res.data.bindingStatus
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.$route.query.type === 'edit') {
            editEquityBindingManagement(this.form).then(res => {
              if (res.code === '200') {
                this.$router.go(-1)
                this.$message.success('操作成功！')
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            addEquityBinding(this.form).then(res => {
              if(res.code === '200') {
                this.$router.go(-1)
                this.$message.success('操作成功！')
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.$router.go(-1)
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
      // 删除提交的图片
      if (fileList.length === 0) {
        this.form.benefitImage = ''
        return
      }
      if (fileList[0].status === "uploading") {
        this.loading = true;
        return;
      }
      if (fileList[0].status === "done") {
        this.loading = false;
        this.form.benefitImage = fileList[0].response.redirect_uri;
      }
    },
    disabledDate(current) {
      return current && moment(current).format('YYYY-MM-DD')  < moment(new Date()).format('YYYY-MM-DD');
    },

    disabledDateTime() {
      // return {
      //   disabledHours: () => this.range(0, 24).splice(4, 20),
      //   disabledMinutes: () => this.range(30, 60),
      //   disabledSeconds: () => [55, 56],
      // };
    },
  }
};
</script>